import { AppProps } from "next/app";
import Head from "next/head";
import { NextIntlClientProvider } from "next-intl";
import "../styles/globals.css";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta property="og:type" content="website" key="og-type" />
        <meta property="og:site_name" content="Picter" key="og-site_name" />
      </Head>
      <NextIntlClientProvider
        messages={pageProps.messages}
        locale="en"
        // Get the current timezone from the browser
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
      >
        <Component {...pageProps} />
      </NextIntlClientProvider>
    </>
  );
}

App.messages = [];
